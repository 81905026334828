.pomodoro-panel {
    background: linear-gradient(135deg, rgba(14, 172, 145, 1) 0%, rgba(203, 195, 195, 1) 100%);
    border: 1px solid rgba(0, 0, 0, 1); 
    width: 25%;
    border-radius: 10px;
    margin-top: 535px;
    margin-left: 40px;
    box-shadow: 0 0 7px rgba(255, 255, 255, 0.5); /* Added box-shadow */
  }

.work-panel {
    background: linear-gradient(135deg, rgba(186, 73, 73, 1) 0%, rgba(203, 195, 195, 1) 100%);
}

.shortBreak-panel {
    background: linear-gradient(135deg, rgba(56, 133, 138, 1) 0%, rgba(203, 195, 195, 1) 100%);
}

.longBreak-panel {
    background: linear-gradient(135deg, rgba(57, 112, 151, 1) 0%, rgba(203, 195, 195, 1) 100%);
}
  
.pomodoro-nav {
    display: flex;
    flex-direction: row;
    width: 75%;
    justify-content: space-around;
    margin: 0 auto;
}

.top-nav {
    transform: translateY(-50%);
}

.bottom-nav {
    width: 100%;
    align-items: center; 
    justify-content: unset;
}

.pomodoro-timer {
    font-size: 50px;
    color: rgba(0, 0, 0, 0.8);
    text-align: center;
    margin: -20px auto;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.pomodoro-button {
    background-color: rgba(0, 0, 0, 0.8); 
    color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(203, 195, 195, 0.8);
    padding: 5px 10px;
    font-size: 16px;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    margin: 2px;
    overflow: hidden;
}

.pomodoro-button:hover {
    background-color: rgba(0, 0, 0, 1);
    color: rgba(14, 172, 145, 1);
    border-color: rgba(14, 172, 145, 1); 
    transform: scale(1.05); 
    box-shadow: 0 0 8px rgba(14, 172, 145, 0.5);
}

.work-hover:hover {
    color: rgba(186, 73, 73, 1);
    border-color: rgba(186, 73, 73, 1); 
    box-shadow: 0 0 8px rgba(186, 73, 73, 0.5);
}

.short-break-hover:hover {
    color: rgba(186, 73, 73, 1);
    border-color: rgba(186, 73, 73, 1); 
    box-shadow: 0 0 8px rgba(186, 73, 73, 0.5);
}

.start-button {
    font-size: 18px;
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0.9);
    color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(255, 255, 255, 0.8);
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.5); /* Added box-shadow */
    transform: translateY(50%);
    margin-left: 13%;
}

.start-button:hover {
    background-color: rgba(0, 0, 0, 1);
    color: rgba(255, 255, 255, 1);
    border-color: rgba(255, 255, 255, 1); 
    transform: scale(1.05); 
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.5); /* Enhanced box-shadow on hover */
    margin-left: 0 auto;
}

.work-active, .work-hover:hover {
    background-color: rgba(0, 0, 0, 1);
    color: rgba(186, 73, 73, 1);
    border-color: rgba(186, 73, 73, 1); 
    transform: scale(1.05); 
    box-shadow: 0 0 8px rgba(186, 73, 73, 0.5);
}

.short-break-active, .short-break-hover:hover {
    background-color: rgba(0, 0, 0, 1);
    color: rgba(56, 133, 138, 1);
    border-color: rgba(56, 133, 138, 1); 
    transform: scale(1.05); 
    box-shadow: 0 0 8px rgba(56, 133, 138, 0.5);
    
}

.long-break-active, .long-break-hover:hover {
    background-color: rgba(0, 0, 0, 1);
    color: rgba(57, 112, 151, 1);
    border-color: rgba(57, 112, 151, 1); 
    transform: scale(1.05); 
    box-shadow: 0 0 8px rgba(57, 112, 151, 0.5);
}

.reset-pomodoro {
    transition: all 0.3s ease-in-out;
    margin-top: 5px;
}

.reset-pomodoro:hover {
    transform: scale(1.05); /* Slightly enlarges on hover */
}

.next-pomodoro {
    transition: all 0.3s ease-in-out;
    margin-top: 5px;
}

.next-pomodoro:hover {
    transform: scale(1.05); /* Slightly enlarges on hover */
}

.switcher {
    margin-left: 5%;
}

.cycles {
    margin-left: 27%;
}