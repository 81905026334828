.taxi-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f7f7f7;
  text-align: center;
}
  
.taxi-container h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.transfer-button {
  padding: 20px 40px;
  font-size: 24px;
  background-color: #007bff;
  border: none;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.transfer-button:hover {
  background-color: #0056b3;
}