@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
  font-family: "ArialRounded";
  src: local("ArialRounded"),
   url("./fonts/arial-rounded-mt/ARLRDBD_0.TTF") format("truetype");
 }

 .font-face-ar {
  font-family: "ArialRounded";
}

body {
  margin: 0;
  font-family: 'ArialRounded', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(109.6deg, rgb(6, 9, 19) 11.2%, rgb(12, 19, 32) 91.1%);
  background-attachment: fixed;
}